import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import App from '@/WuiApp';
import Layout from '@c/layout/Layout';

import { useDocumentTitle } from 'hooks';

import ProofLobby from './ProofLobby';

const ProofLobbyPage = ({ title }) => {
  useDocumentTitle(title);

  return (
    <App showFooter={false}>
      <Layout>
        <ProofLobby />
      </Layout>
    </App>
  );
};

ProofLobbyPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default observer(ProofLobbyPage);
