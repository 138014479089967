import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { OldAppRedirects, Urls } from 'urls';
import { getApplicableSections } from 'ui/sections/sections';
import { withRoot } from 'ui/Root';

import SubscriptionPlanPricing from 'ui/pricing/SubscriptionPlanPricing';
import Login from '@c/authentication/Login';
import ResetPassword from '@c/authentication/ResetPassword';
import ResetPasswordComplete from '@c/authentication/ResetPasswordComplete';
import RegisterGroupon from '@c/authentication/RegisterGroupon';
import RegisterCovid from '@c/authentication/RegisterCovid';
import Register from '@c/authentication/Register';
import SubscriptionPicker from 'ui/SubscriptionPicker';
import CheckoutPicker from 'ui/CheckoutPicker';
import NotFound from 'ui/NotFound';
import KBALocked from 'ui/KBALocked';
import NotaryCallRoom from 'ui/execution/notary/NotaryCallRoom';
import NotarySessionManager from 'ui/execution/notary/NotarySessionManager';
import WitnessCallRoom from 'ui/execution/witness/WitnessCallRoom';
import WitnessSessionManager from 'ui/execution/witness/WitnessSessionManager';
import Storage from 'ui/Storage';
import DocumentVerificationLanding from 'material-ui/pages/DocumentVerificationLanding';
import DocumentVerificationViewer from 'ui/DocumentVerificationViewer';
import SubscriptionCheckout from 'ui/checkout/SubscriptionCheckout';
import CheckoutSuccess from '@/pages/Checkout/Success';
import IndependentBeneficiaryAddresses from 'material-ui/components/IndependentBeneficiaryAddresses';
import CheckoutV6 from 'material-ui/pages/checkout-v6/CheckoutV6';
import PlanDashboard from 'material-ui/pages/PlanDashboard';
import Account from '@/pages/account';
import ProtectedRoute from 'ui/components/authentication/ProtectedRoute';
import PostPurchaseDashboard from 'material-ui/pages/PostPurchaseDashboard';
import DepByDefaultSummary from '@/pages/DepByDefaultSummary';
import AttorneyReview from '@/pages/AttorneyReview';
import ProofLobbyPage from '@/pages/documents/ProofLobbyPage';

function getUnprotectedRoutes() {
  const redirects = [];
  for (const redirect of OldAppRedirects) {
    redirects.push([redirect.from, () => <Redirect to={redirect.to} />]);
  }

  return [
    [
      Urls.documentVerificationLanding,
      DocumentVerificationLanding,
      { title: 'Document Verification' },
    ],
    [
      Urls.documentVerificationViewer,
      DocumentVerificationViewer,
      { title: 'Document Verification Viewer' },
    ],
    [Urls.register, Register, { title: 'Register' }],
    [Urls.registerGroupon, RegisterGroupon, { title: 'Register Groupon' }],
    [Urls.registerCovid, RegisterCovid, { title: 'Register Covid' }],
    [Urls.login, Login, { title: 'Login' }],
    [Urls.resetPassword, ResetPassword, { title: 'Reset Password' }],
    [Urls.resetPasswordComplete, ResetPasswordComplete, { title: 'Reset Password Complete' }],
    [Urls.depByDefaultSummary, DepByDefaultSummary, { title: 'Dep Summary' }],
    [Urls.attorneyReview, AttorneyReview, { title: 'Attorney Review' }],

    ...redirects,
  ].map(([path, Component, props]) => (
    <Route key={path} exact path={path} render={withRoot(Component, props)} />
  ));
}

function getProtectedRoutes(clientData, serverData, experiments, clientDataHolder) {
  const getGroupedSectionRoutes = (sections, linkSections = false) => {
    const results = [];

    for (const [index, section] of sections.entries()) {
      let finalUrl = '';

      // optionally send this section to the beginning of the next one
      if (linkSections && index !== sections.length - 1) {
        finalUrl = sections[index + 1].firstUrl(clientData, serverData, experiments);
      }

      results.push(
        ...section.getPaths(clientData, serverData, experiments, {
          finalUrl,
          title: section.title,
        }),
      );
    }

    return results;
  };

  const sectionRoutes = [];
  const sections = getApplicableSections(clientData, serverData, experiments);

  sectionRoutes.push(...getGroupedSectionRoutes(sections.plan, true));
  sectionRoutes.push(...getGroupedSectionRoutes(sections.file));
  sectionRoutes.push(...getGroupedSectionRoutes(sections.sign));
  sectionRoutes.push(...getGroupedSectionRoutes(sections.notarize));
  sectionRoutes.push(...getGroupedSectionRoutes(sections.documents));
  sectionRoutes.push(...getGroupedSectionRoutes(sections.notaryDetails));

  return [
    [Urls.planDashboard, PlanDashboard, { title: 'Plan Dashboard' }],
    [Account.url, Account, { title: 'Account' }],
    [Urls.file, PostPurchaseDashboard, { title: 'Post Purchase Dashboard' }],
    [SubscriptionPlanPricing.url, SubscriptionPlanPricing, { title: 'Subscription Plan Pricing' }],
    [CheckoutPicker.url, CheckoutPicker, { title: 'Checkout Picker' }],
    [SubscriptionPicker.url, SubscriptionPicker, { title: 'Subscription Picker' }],
    [CheckoutSuccess.url, CheckoutSuccess, { title: 'Checkout Success' }],
    [Urls.subscriptionCheckout, SubscriptionCheckout, { title: 'Subscription Checkout' }],
    [Urls.storage, Storage, { title: 'Storage' }],
    [KBALocked.url, KBALocked, { title: 'KBA Locked' }],
    ['/notary/', NotarySessionManager, { title: 'Notary Session' }],
    ['/notary/call/:sessionId', NotaryCallRoom, { title: 'Notary Call Room' }],
    ['/witness/', WitnessSessionManager, { title: 'Witness Session' }],
    ['/witness/call/:sessionId/:position', WitnessCallRoom, { title: 'Witness Call Room' }],
    [Urls.checkout, CheckoutV6, { title: 'Checkout' }],
    [Urls.proofNotarize, ProofLobbyPage, { title: 'Preparing to Notarize' }],

    ...(experiments.bennyAddressPostCheckout
      ? [[IndependentBeneficiaryAddresses.url, IndependentBeneficiaryAddresses]]
      : []),

    ...sectionRoutes,
  ].map(([path, Component, props, key]) => (
    <ProtectedRoute
      key={key || path}
      exact
      path={path}
      render={withRoot(Component, props)}
      redirectTo={{
        pathname: Urls.login,
        search: window.location.search,
        state: { referrer: clientDataHolder.props.location },
      }}
      allowed={clientDataHolder.isAuthenticated}
    />
  ));
}

export function getTopLevelRoutes(clientData, serverData, experiments, clientDataHolder) {
  const unprotectedRoutes = getUnprotectedRoutes();
  const protectedRoutes = getProtectedRoutes(clientData, serverData, experiments, clientDataHolder);

  return [...unprotectedRoutes, ...protectedRoutes, <Route key="" component={NotFound} />];
}
