export const slugs = {
  aboutYou: '/about-you/',
  family: 'family/',
  realEstate: 'real-estate/',
};

export const Urls = {
  planDashboard: '/',
  account: '/my-account/',
  sectionAboutYou: slugs.aboutYou,
  family: slugs.aboutYou + slugs.family,
  realEstate: slugs.aboutYou + slugs.realEstate,
  sectionGuardians: '/guardians/',
  sectionAssetBeneficiaries: '/asset-beneficiaries/',
  sectionExecutors: '/finances/',
  sectionHealthcare: '/healthcare/',
  sectionNotify: '/notify/',
  signSection: '/sign-section/',
  review: '/review-docs/',
  register: '/register/',
  registerGroupon: '/register/groupon/',
  registerCovid: '/metlife/',
  checkout: '/checkout/',
  checkoutPicker: '/checkout-picker/',
  subscriptionPicker: '/subscription-picker/',
  subscriptionCheckout: '/subscription-checkout/',
  file: '/file/',
  login: '/login/',
  changePassword: '/change-password/',
  resetPassword: '/reset-password/',
  resetPasswordComplete: '/reset-password/complete/:userId/:token/',
  probateAvoidance: '/about-you/plan-notice/',
  notarize: '/e-sign/',
  notarizePrep: '/e-sign/prep/',
  notarizeReviewReqs: '/e-sign/prep/review-reqs/',
  notarizeSignProc: '/e-sign/prep/sign-proc/',
  notarizeAboutNotary: '/e-sign/prep/about-notary/',
  notarizeDocReview: '/e-sign/prep/doc-review/',
  proofNotarize: '/proof-e-sign/',
  storage: '/storage/',
  notaryDetails: '/notary-details/',
  documents: '/documents/',
  notaryJournal: '/notarization-tools/documents/',
  documentVerificationLanding: '/verify/',
  documentVerificationViewer: '/verify/view/',
  independentBeneficiaryAddresses: '/beneficiary-address/',
  reviewBase: 'review/',
  depByDefaultSummary: '/summary/',
  attorneyReview: '/attorney-review/',
  // final URL TBD
};

export default Urls;

export const OldAppRedirects = [
  { from: '/groupon/', to: '/register/groupon/' },
  { from: '/join', to: '/register/' },
  { from: '/legacy-checkout/', to: Urls.checkout },
  { from: '/referrals/', to: Urls.sectionNotify },

  // There used to be an introduction page at the old URL,
  //   but we removed it and instead direct you to the
  //   first page you can input data into.
  { from: '/about-you/initial', to: '/about-you/' },

  // These pages were removed or renamed as part of the
  //   switch to Material UI.
  { from: '/about-you/planning-for-spouse', to: '/about-you/family/' },
  { from: '/about-you/dob', to: '/about-you/' },
  { from: '/about-you/spouse', to: '/about-you/family/' },
  { from: '/about-you/children', to: '/about-you/family/' },
  { from: '/done/', to: '/file/' },

  { from: '/document-verify/', to: '/verify/' },
  { from: '/document-verify/view/', to: '/verify/view/' },
  { from: '/start-my-will/hello/', to: Urls.login },
  { from: '/start-my-will/', to: Urls.login },

  // E-Sign initial page moved to the start of a prep section
  { from: '/e-sign/', to: Urls.notarizePrep },
];

function getSearchParam(param) {
  return new URLSearchParams(window.location ? window.location.search : null).get(param);
}

export function getRedirectToUrl() {
  return getSearchParam('redirectTo');
}

export function shouldShowRenderError() {
  return Boolean(getSearchParam('showRenderError'));
}

export function urlWithRedirectToAppended(url) {
  const redirectTo = getRedirectToUrl();

  if (redirectTo) {
    return `${url}?redirectTo=${redirectTo}`;
  }

  return url;
}

export function redirectWithoutPrompt(url) {
  window.onbeforeunload = null;
  window.location = url;
}

export function redirectToJournal(withError) {
  // We use a real redirect here and avoid showing the window close
  // warning because the notary journal is handled by the
  // notarization-tools app.
  const url = withError ? `${Urls.notaryJournal}?unfinishedError=1` : Urls.notaryJournal;
  redirectWithoutPrompt(url);
}
